<template>
  <dynamic-tabs
    tabs-id="user-account-tabs"
    :tabs="tabs"/>
</template>

<script>
import DynamicTabs from '@/views/modules/_components/DynamicTabs.vue';
import UserAccountProfile from '@/views/modules/user/user-account/user-account-profile/UserAccountProfile.vue';
import UserAccountSecurity from '@/views/modules/user/user-account/user-account-security/UserAccountSecurity.vue';
import UserAccountSettingsDetails from '@/views/modules/user/user-account/user-account-settings/UserAccountSettingsDetails.vue';

/**
 * User account tabs
 *
 * @module views/modules/user-account/UserAccountTabs
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} [tabs=[...]] - tabs to show
 */
export default {
  name: 'UserAccountTabs',
  components: {
    DynamicTabs,
  },
  data() {
    return {
      tabs: [
        {
          icon: 'icon-user',
          label: this.$t('$General.Profile'),
          component: UserAccountProfile,
          path: 'profile',
        },
        {
          icon: 'icon-info',
          label: this.$t('$UserSettings.$Account.$Details.TabTitle'),
          component: UserAccountSettingsDetails,
          path: 'details',
        },
        {
          icon: 'icon-shield',
          label: this.$t('$General.Security'),
          component: UserAccountSecurity,
          path: 'security',
        },
      ],
    };
  },
};
</script>
