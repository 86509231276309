<template>
  <div>
    <user-account-menu v-if="$mq === $enums.mqBreakpoints.MOBILE"/>
    <user-account-tabs v-else/>
  </div>
</template>

<script>
import UserAccountTabs from '@/views/modules/user/user-account/UserAccountTabs.vue';
import UserAccountMenu from '@/views/modules/user/user-account/UserAccountMobileMenu.vue';

/**
 * User account configurations and details
 *
 * @module views/modules/user-account/UserAccount
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'UserAccount',
  components: {
    UserAccountTabs,
    UserAccountMenu,
  },
};
</script>
