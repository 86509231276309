<template>
  <div>
    <mobile-menu-group
      v-for="(group, index) in groups"
      :id="group.TabIdName"
      :key="index"
      :class="{'mt-base': index > 0}"
      :header-text="group.headerText"
      :items="group.items"/>
  </div>
</template>

<script>
import MobileMenuGroup from '@/views/modules/_components/MobileMenuGroup.vue';

/**
 * User account menu
 *
 * @module views/modules/user-account/UserAccountMenu
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} [groups=[...]] - groups to show in the menu
 * @vue-event {void} logout - logout
 */
export default {
  name: 'UserAccountMenu',
  i18n: {
    messages: {
      en: {
        ProfileText: 'Manage your profile information',
        AccountDetailsText: 'Manage the business account details',
        SecurityText: 'Keep your account safe with security recommendations',
        OverviewMonthlyPackageText: 'Manage your monthly package',
        OverviewPrepaidPackagesText: 'Manage your prepaid packages',
        BillingTopUpAccountText: 'Upgrade your account with packages',
        AutoTopUpAccountText: 'Set up your auto top-up, so that you do not run out of balance',
      },
    },
  },
  components: {
    MobileMenuGroup,
  },
  data() {
    return {
      groups: [
        {
          headerText: '',
          TabIdName: 'mobile-group-account',
          items: [
            {
              title: this.$t('$General.Profile'),
              text: this.$t('ProfileText'),
              icon: 'UserIcon',
              path: '/account-mobile/profile',
            },
            {
              title: this.$t('$UserSettings.$Account.$Details.TabTitle'),
              text: this.$t('AccountDetailsText'),
              icon: 'InfoIcon',
              path: '/account-mobile/details',
            },
            {
              title: this.$t('$General.Security'),
              text: this.$t('SecurityText'),
              icon: 'ShieldIcon',
              path: '/account-mobile/security',
            },
          ],
        },
      ],
    };
  },
};
</script>
